import React, {useEffect, useState} from 'react';
import styles from './invite-members.module.scss';
import CgIcon from '@/components/atoms/cg-icon';
import {Row, Col, Button, Alert} from 'react-bootstrap';
import FormControl from '@/components/atoms/form-control';
import {isEmail, isEmpty, trim} from 'validator';
import RelationshipsSelect from '@/components/molecules/relationships-select';
import PropTypes from 'prop-types';
import {INVITE_NEW_MEMBERS} from '@/constants/endpoints.constant';
import {useHttpPost} from '@/hooks/use-http';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from '@/hooks';

const InviteMembers = ({careRecipient, hideSaveButton = false, inviteData = [], sendInviteData = () => {}}) => {
    const setInputDefault = () => {
        return inviteData.length > 0 ? inviteData : [{name: '', email: '', relationship: ''}];
    }
    const [inputList, setInputList] = useState(setInputDefault);
    const {execute: post, data: createdNewMembers} = useHttpPost();
    const history = useNavigate();
    const [errors, setErrors] = useState(false);
    const [relationshipError, setRelationshipError] = useState(false)
    const [submitting, setSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        // Validate form on component mount or inviteData change
        setIsFormValid(validateForm());
    }, [inviteData]);

    const validateForm = () => {
        return inputList.every(input => {
            return !isEmpty(trim(input.name)) && isEmail(trim(input.email)) && !isEmpty(trim(input.email)) && !isEmpty(input.relationship);
        });
    };


    const handleInputChange = (e, index) => {
        const invitedMembersList = [...inputList];
        invitedMembersList[index][e.target.name] = e.target.value || e.currentTarget.value;
        if (invitedMembersList.length === 1 && invitedMembersList[index].email === '' && invitedMembersList[index].name === ''){
            setInputList([{name: '', email: '', relationship: ''}])
            sendInviteData([])
            setErrors(false)
        } else if (invitedMembersList.length > 1 && invitedMembersList[index].email === '' && invitedMembersList[index].name === ''){
            // Removes specified invited caregiver from list, splice was already being used in the logic on handleRemoveMember.
            invitedMembersList.splice(index, 1)
            setInputList([...invitedMembersList])
            sendInviteData([...invitedMembersList])
            setErrors(false)
        } else {
            setInputList(invitedMembersList);
            sendInviteData(inputList);
        }
        setIsFormValid(validateForm());
    };

    const handleRelationChange = (key, index) => {
      const invitedMembersList = [...inputList];
      invitedMembersList[index]['relationship'] = key;
      setInputList(invitedMembersList);
      sendInviteData(inputList);
      setIsFormValid(validateForm());
  };

    const handleRemoveMember = (index) => {
        const invitedMembersList = [...inputList];
        // Removes specified invited caregiver from list, splice was already being used here.
        invitedMembersList.splice(index, 1);
        setInputList(invitedMembersList);
        sendInviteData(invitedMembersList);
    };

    const handleAddMember = () => {
        setInputList([...inputList, {name: '', email: '', relationship: ''}]);
    };

    const saveMembers = () => {
        let errors = false;
        inputList.map((input, index) => {

            if (isEmpty(trim(input.name))) {
                const el = document.getElementById(`${index}-name`);
                el.classList.add('is-invalid');
                errors = true;
                setErrorMessage(t('moleculeTranslations.inviteMembers.saveMember.error.nameEmpty'));
            }
            if (isEmpty(trim(input.email))) {
                const el = document.getElementById(`${index}-email`);
                el.classList.add('is-invalid');
                errors = true;
                setErrorMessage(t('moleculeTranslations.inviteMembers.saveMember.error.emailEmpty'));
            }
            if (isEmpty(input.relationship)) {
                errors = true;
                setErrorMessage(t('moleculeTranslations.inviteMembers.saveMember.error.relationshipEmpty'));
                setRelationshipError(isEmpty(input.relationship))
            }
            if(isEmpty(trim(input.name)) && isEmpty(trim(input.email)) && isEmpty(input.relationship)){
                errors = true;
                setErrorMessage(t('moleculeTranslations.inviteMembers.saveMember.error.allFieldsEmpty'));
            }
        })

        if (!errors) {
            setErrors(false);
            setSubmitting(true);
            post(INVITE_NEW_MEMBERS(careRecipient.uuid), {invites: inputList});
        } else {
            setErrors(true);
        }
    }

    useEffect(() => {
        if (createdNewMembers) {
            history(`/manage/care-recipients/${careRecipient.uuid}/members`, { replace: true});
        }
    }, [createdNewMembers])

    return (<div className={styles.cgInviteMembers}>
        {errors && <Alert variant="danger">{errorMessage}</Alert>}
        {inputList.map((x, i) => {
            return (
              <div key={i}>
                <Row className={styles.cgInviteMembers__row}>
                  <Col sm={12} lg={3}>
                    <FormControl
                      className={styles.cgInviteMembers__input}
                      id={`${i}-name`}
                      name="name"
                      placeholder={t('moleculeTranslations.inviteMembers.name.placeholder')}
                      value={x.name}
                      validators={[{ validator: (val) => !isEmpty(trim(val)), errorMsg: t('moleculeTranslations.inviteMembers.name.error.required'), }]}
                      onChange={(e) => handleInputChange(e, i)}
                      type="text"
                    ></FormControl>
                  </Col>

                  <Col sm={12} lg={3}>
                    <RelationshipsSelect
                      name="relationship"
                      value={inputList[i].relationship}
                      onChange={(label,value,key) => handleRelationChange(key, i)}
                      className={styles.cgInviteMembers__input}
                      dropdownClassName={styles.cgInviteMembers__dropdownOption}
                      ariaLabel="Select the relationship to the care recipient"
                      defaultOptionName={`${t('moleculeTranslations.relationshipPlaceholder.text1')} ${careRecipient?.fullname || careRecipient?.firstname} ${t('moleculeTranslations.relationshipPlaceholder.text2')}`}
                      error={relationshipError}
                    />
                  </Col>
                  <Col sm={12} lg={3}>
                    <FormControl
                      className={styles.cgInviteMembers__input}
                      name="email"
                      id={`${i}-email`}
                      placeholder={t('moleculeTranslations.inviteMembers.email.placeholder')}
                      value={x.email}
                      onChange={(e) => handleInputChange(e, i)}
                      validators={[
                        {
                          validator: (val) => !isEmpty(trim(val)),
                          errorMsg: t('moleculeTranslations.inviteMembers.email.error.required'),
                        },
                        {
                          validator: isEmail,
                          errorMsg: t('moleculeTranslations.inviteMembers.email.error.invalid'),
                        },
                      ]}
                      type="text"
                    ></FormControl>
                  </Col>
                  <Col lg={1} sm={4}>
                    {inputList.length > 1 && (
                      <button
                        className={styles.cgInviteMembers__remove_member}
                        onClick={() => handleRemoveMember(i)}
                      >
                          <CgIcon iconName="IoMdClose" /><span className="d-sm-inline d-lg-none">{t('moleculeTranslations.inviteMembers.action.remove')}</span>
                      </button>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {inputList.length - 1 === i && (
                      <div>
                        <Button
                          size="lg"
                          className={styles.cgInviteMembers__add_member}
                          onClick={handleAddMember}
                          disabled={submitting}
                        >
                            {`+ ${t('moleculeTranslations.inviteMembers.action.addAnotherMember')}`}
                        </Button>
                        {!hideSaveButton && (
                          <Button
                            size="lg"
                            variant="primary"
                            className={styles.cgInviteMembers__save_button}
                            onClick={saveMembers}
                            disabled={!isFormValid || submitting}
                          >
                              {t('moleculeTranslations.inviteMembers.action.save')}
                          </Button>
                        )}
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            );
        })}
    </div>)
}
InviteMembers.propTypes = {
    careRecipient: PropTypes.object,
    hideSaveButton: PropTypes.bool,
    inviteData: PropTypes.array,
    sendInviteData: PropTypes.func
}

export default InviteMembers;
