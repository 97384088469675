import {ArticlePageTranslations} from './article';
import {AwardPageTranslations} from './award';
import {AboutTranslations} from './about';
import {VisionaryAwardsTranslations} from './visionary-awards';
import {HomeTranslations} from './home';
import {ChampionsTranslations} from './champions';
import {CareRecipientTranslations} from './manage/care-recipients';
import {MembersTranslations} from './manage/members';
import {OneLinkTranslations} from './one-link';
import {PodcastsTranslations} from './podcasts';
import {VideosTranslations} from './videos';
import {CommunityNewsRoomTranslations} from './community-newsroom';
import {MarketingTranslations} from './marketing';
import {AccountTranslations} from './manage/account';
import {AuthenticationTranslations} from './authentication';
import {SupportTranslations} from './support';
import {ProviderPageTranslations} from './provider';
import {LibraryTranslations} from './library';
import {CollectionListTranslations} from './collections'
import {PartnerTranslations} from './partners';
import {RewardsTranslations} from './manage/rewards';

const Index = {
    follow: 'Seguir',
    save: 'Salvar',
    following: 'Siguiendo',
    saved: 'Salvado',
    followTypes: {
        author: 'Contribuyente',
        brand: 'Marca',
    },
    savedTypes: {
        post: 'Artículo',
        brand: 'Marca',
    },
    selectRelationship: 'Relación seleccionada',
    all: 'Todas',
    articles: 'Artículos',
    podcasts: 'Podcasts',
    videos: 'Videos',
    filter: 'Filtrar',
    category: 'Categoría',
    publishDate: 'Fecha de publicación',
    author: 'Autora',
    anytime: 'En cualquier momento',
    last24: 'Últimas 24 horas',
    week: 'Esta semana',
    month: 'Este mes',
    year: 'Este año',
    noMatchesFound: 'No se encontraron coincidencias.',
    promptText: 'Escribe para buscar...',
    searchText: 'Buscando...',
    serviceAreas: 'Áreas de servicio:',
    mainLocation: 'Ubicación principal:',
    servicesAmenities: 'Servicios y comodidades',
    requestErrorMessage:'Hubo un error al procesar su solicitud:'
};

const PageTranslations = Object.assign(
    Index,
    HomeTranslations,
    CareRecipientTranslations,
    MembersTranslations,
    OneLinkTranslations,
    AwardPageTranslations,
    VisionaryAwardsTranslations,
    AboutTranslations,
    ChampionsTranslations,
    PodcastsTranslations,
    VideosTranslations,
    CommunityNewsRoomTranslations,
    MarketingTranslations,
    AccountTranslations,
    AuthenticationTranslations,
    ArticlePageTranslations,
    SupportTranslations,
    ProviderPageTranslations,
    LibraryTranslations,
    CollectionListTranslations,
    PartnerTranslations,
    RewardsTranslations
);

export default PageTranslations;
