import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './nfcs-bar.module.scss';
import {useObservable, useTranslation} from '@/hooks';
import { LayoutObservable } from '@/observables/layout.observable';
import StreamIcon from '@/assets/icons/stream.svg';
import RightArrow from '@/assets/icons/right-arrow-purple.svg';
import { isBefore, isAfter } from '@/utils/timeCheck';
import NfcsLogo from '@/assets/images/nfcs/2023/nfcs_logo.png';
import NfcsTagline from '@/assets/images/nfcs/2023/nfcs_tagline.png';
import NfcsTaglineMobile from '@/assets/images/nfcs/2023/nfcs_tagline_mobile.png';
import NfcsPresenter from '@/assets/images/nfcs/2023/nfcs_presenter.png';
import NfcsSpeakers from '@/assets/images/nfcs/2023/nfcs_speakers.png';

const NFCSBar = ({ ...props }) => {
  const { t } = useTranslation();
  const [isNfcsHeroEnabled] = useObservable(LayoutObservable.nfcsHeroEnabled$)
  const [isNfcsBarEnabled] = useObservable(LayoutObservable.nfcsBarEnabled$)

  useEffect(() => {
    if (isAfter(11, 10, 0) && isBefore(11, 12, 0)) {
      LayoutObservable.setNfcsHeroEnabled(true);
      LayoutObservable.setNfcsHeroInView(window.scrollY <= 720);
      const handleScroll = () => {
        if (window.scrollY <= 720) {
          // hero should be enabled
          if (!LayoutObservable.nfcsHeroInViewSync.getValue()) {
            LayoutObservable.setNfcsHeroInView(true);
          }
        } else {
          // hero should be disabled
          if (LayoutObservable.nfcsHeroInViewSync.getValue()) {
            LayoutObservable.setNfcsHeroInView(false);
          }
        }
      };
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    } else if (isBefore(11, 10, 0)) {
      LayoutObservable.setNfcsBarEnabled(true); // just to make sure
    }
  }, []);

  const getHeroStreamTime = () => {
    if (isAfter(11, 11, 0)) {
      return t('moleculeTranslations.nfcs.watch_today_saturday')
    }
    return t('moleculeTranslations.nfcs.watch_today_friday')
  }

  if (isNfcsBarEnabled) {
    return (
      <div className={`${styles.cgNFCSBar} ${props.className}`}>
        <div className="d-flex flex-row align-items-center">
          <StreamIcon />
          <div className="ml-2 text-white">{t('moleculeTranslations.nfcs.alert')}</div>
        </div>
        <a href="https://nfcs.caregiving.com/2023" target="_blank" rel="noreferrer" className={styles.cgNFCSBar__register}>
          <div>{t('moleculeTranslations.nfcs.register')}</div>
          <RightArrow />
        </a>
      </div>
    );
  }
  if (isNfcsHeroEnabled) {
    return (
      <div className={`${styles.cgNFCSHero} ${props.className}`}>
        <div><img className={`${styles.cgNFCSHero_img} ${styles.cgNFCSHero_img__logo}`} src={NfcsLogo} alt="NFCS Logo" /></div>
        <div>
          <img className={`${styles.cgNFCSHero_img} ${styles.cgNFCSHero_img__tagline}`} src={NfcsTaglineMobile} alt="Engage Today. Empower Tomorrow" />
          <img className={`${styles.cgNFCSHero_img} ${styles.cgNFCSHero_img__tagline_desktop}`} src={NfcsTagline} alt="Engage Today. Empower Tomorrow" />
        </div>
        <div><img className={`${styles.cgNFCSHero_img} ${styles.cgNFCSHero_img__presenter}`} src={NfcsPresenter} alt="NFCS Presented by Walmart" /></div>
        <div>
          <p>{getHeroStreamTime()}</p>
          <a href="https://nfcs.caregiving.com/2023" target="_blank" rel="noreferrer">
            <StreamIcon/>
            <span className="ml-2 text-white">{t('moleculeTranslations.nfcs.stream_live')}</span>
          </a>
        </div>
        <div><img className={`${styles.cgNFCSHero_img} ${styles.cgNFCSHero_img__speakers}`} src={NfcsSpeakers} alt="Preview of NFCS Speakers" /></div>
      </div>
    );
  }
  return null;
};

NFCSBar.propTypes = {
  className: PropTypes.string,
}

export default NFCSBar;
