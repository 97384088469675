import { BehaviorSubject } from 'rxjs';
import { LocalStorageKeys } from '@/constants/local-storage-keys.constant';
import LocalStorage from '@/observables/localStorage.observable';

const supportLayer = LocalStorage.getItem(LocalStorageKeys.SUPPORT_LAYER);
const slInfo = supportLayer && JSON.parse(supportLayer) || {};

const slSubject = new BehaviorSubject(slInfo);

export const SupportLayerObservable = Object.freeze({
  updateSupportLayer: (data) => {
    if (data) {
      LocalStorage.setItem(LocalStorageKeys.SUPPORT_LAYER, JSON.stringify(data));
      slSubject.next(data);
    } else {
      LocalStorage.removeItem(LocalStorageKeys.SUPPORT_LAYER);
      slSubject.next({});
    }
  },
  hasSupportLayer: (subject, value) => {
    if (!subject) {
      return false;
    }
    if (!subject?.list) {
      return false
    }
    return subject.list.indexOf(value) !== -1;
  },
  supportLayer$: slSubject.asObservable(),
});
