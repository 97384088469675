import React, {useEffect, useState} from 'react';
import styles from '../members.module.scss'
import {useNavigate, useParams} from 'react-router-dom';
import {Col, Row, Container} from 'react-bootstrap';
import Back from '@/components/atoms/back';
import InviteMembers from '@/components/molecules/invite-members';
import {CARE_RECIPIENT_BY_UUID} from '@/constants/endpoints.constant';
import {useHttpGet} from '@/hooks/use-http';
import {useTranslation} from '@/hooks';

const CreateMembers = () =>{
    const {uuid} = useParams();
    const history = useNavigate();
    const {execute: get, data:careRecipientResponse} = useHttpGet();
    const [careRecipient, setCareRecipient] = useState(null);
    const {t} = useTranslation();

    useEffect(() =>{
        if(uuid){
            get(CARE_RECIPIENT_BY_UUID(uuid));
        }
    }, [uuid])

    useEffect(() =>{
        if(careRecipientResponse){
            setCareRecipient(careRecipientResponse);
        }
    }, [careRecipientResponse])

    return (careRecipient &&
        <Container className={styles.cgMembers}>
            <Row>
                <Col sm={12}>
                    <Back text={`${careRecipient.fullname}'s Members`} className={styles.cgMembers__back} onClickBack={() => history(-1)}/>
                </Col>
            </Row>
            <Row>
                <Col className='my-3'>
                    <h4>{t('membersTranslations.addNewMember')}</h4>
                    <p>{`${t('membersTranslations.inviteDescription')} ${careRecipient.fullname}'s ${t('membersTranslations.profile')}`}</p>
                    <InviteMembers careRecipient={careRecipient}/>
                </Col>
            </Row>
        </Container>
    )
}

export default CreateMembers;
