export const ProviderPageTranslations = {
  providerPageTranslations: {
    caregiverSupport: 'Caregiver Support',
    insuranceCompany: 'Insurance Company',
    caregivingPromise: 'For the good of the industry, the Caregiving Promise is to never sell leads or charge communities a commission.',
    more: 'more',
    about: 'About',
    follow: 'Follow',
    following: 'Following',
    save: 'Save',
    saved: 'Saved',
    share: 'Share',
    discoveryItems: {
      caregiving101: 'Caregiving 101',
      longTermCare: 'Long-term care',
      personalWellness: 'Personal wellness',
      financialLegal: 'Financial & legal',
      healthMedical: 'Health & medical',
    },
    types: {
      contributor: 'Contributor'
    },
  },
};
