import { useEffect, useState } from 'react';

export const useObservable = (observable, defaultValue) => {
  const [data, setData] = useState(defaultValue);

  useEffect(() => {
    const subscription = observable.subscribe((data) => setData(data));

    return () => subscription?.unsubscribe?.();
  }, []);

  return [data];
}
