import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import Concert from '@/components/atoms/concert';
import PrivacyPolicy from '@/components/atoms/privacy-policy';
import ReprintPermission from '@/components/atoms/reprint-permission';
import TermsOfUse from '@/components/atoms/terms-of-use';
import MarketingPage from '@/components/pages/marketing';
import OurStory from '@/components/pages/our-story';
import About from '@/components/pages/about';
import Layout from '@/components/templates/layout';
import loadable from '@loadable/component';
import VideoProfile from './components/pages/videos/video-profile';
import CreateMembers from '@/components/pages/manage/members/create';
import CareSituation from '@/components/pages/signup/care-situation';

const Home = loadable(() => import('@/components/pages/home'));
const Article = loadable(() => import('@/components/pages/article'));
const Jobs = loadable(() => import('@/components/pages/jobs'));
const Podcasts = loadable(() => import('@/components/pages/podcasts'));
const Contributor = loadable(() => import('@/components/pages/contributor'));
const ContributorDetails = loadable(() => import('@/components/pages/contributors'));
const ContributorContact = loadable(() => import('@/components/pages/contributor-contact'));
const PodcastsDetailsComponent = loadable(() => import('@/components/pages/podcasts/podcasts-details'));
const VisionaryAwards = loadable(() => import('@/components/pages/visionary-awards'));
const AwardsVideo = loadable(() => import('@/components/atoms/awards-video'));
const Search = loadable(() => import('@/components/pages/search'));
const AuthRoute = loadable(() => import('@/components/molecules/auth-route'));
const Login = loadable(() => import('@/components/pages/authentication/login'));
const SsoAuthorize = loadable(() => import('@/components/pages/authentication/sso-authorize'));
const LogoutComponent = loadable(() => import('@/components/pages/authentication/logout'));
const Videos = loadable(() => import('@/components/pages/videos'));
const Manage = loadable(() => import('@/components/pages/manage'));
const ManageMembers =  loadable(() => import('@/components/pages/manage/members'));
const Signup = loadable(() => import('@/components/pages/signup'));
const ForgotPassword = loadable(() => import('@/components/pages/authentication/forgot-password'));
const CreateCareRecipient = loadable(() => import('@/components/pages/manage/care-recipients/create'));
const Libraries =  loadable(() => import('@/components/pages/libraries'));
const ForumRedirect = loadable(() => import('@/components/pages/forum-redirect'));
const ChampionsJoin = loadable(() => import('@/components/pages/champions-join'));
const InsuranceLanding = loadable(() => import('@/components/pages/one-link/insurance'));
const Support = loadable(() => import('@/components/pages/support'));
const Awards = loadable(() => import('@/components/pages/award'));
const Brand = loadable(() => import('@/components/pages/brands/wrapper'));
const MembersOnboard = loadable(() => import('@/components/pages/manage/care-recipients/members-onboard'));
const Collections = loadable(() => import('@/components/pages/collections'));
const CollectionComponent = loadable(() => import('@/components/pages/collections/collection'));
const PartnerComponent = loadable(() => import('@/components/pages/partners'));

const routes = () => (
  <Layout>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/care-situation/:code" element={<CareSituation />} />
      <Route path="/partner/:brand_key/category/:category_key" element={<Brand prefix="partner" />} />
      <Route path="/partner/:brand_key/content/:url" element={<Article />} />
      <Route path="/partner/*" element={<Brand prefix="partner" />} />
      <Route path="/manage/care-recipients/:uuid/members/create" element={<CreateMembers />} />
      <Route path="/manage/care-recipients/:uuid/members" element={<ManageMembers />} />
      <Route path="/manage/care-recipients/members/invite/:code" element={<MembersOnboard />} />
      <Route path="/onelink/*" element={<InsuranceLanding />} />
      <Route path="/about/contributors/contact" element={<ContributorContact />} />
      <Route path="/about/champions/join" element={<ChampionsJoin />} />
      <Route path="/champions" element={<Navigate to="/about/champions/join" />} />
      <Route path="/about" element={<About />} />
      <Route path="/about/ourstory" element={<OurStory />} />
      <Route path="/(jobs|careers)/*" element={<Article />} />
      <Route path="/careers" element={<Jobs />} />
      <Route path="/research" element={<Libraries groupId="research" />} />
      <Route path="/research/*" element={<Article />} />
      <Route path="/articles/:id" element={<Libraries />} />
      <Route path="/articles" element={<Libraries />} />
      <Route path="/podcasts/*" element={<PodcastsDetailsComponent />} />
      <Route path="/podcasts" element={<Podcasts />} />
      <Route path="/awards" element={<Awards />} />
      <Route path="/awards/:year/stream" element={<AwardsVideo />} />
      <Route path="/awards/:year" element={<VisionaryAwards />} />
      <Route path="/newsroom" element={<Libraries groupId="newsroom" />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-use" element={<TermsOfUse />} />
      <Route path="/reprint-permission" element={<ReprintPermission />} />
      <Route path="/community" element={<Libraries groupId="community" />} />
      <Route path="/search/:type" element={<Search />} />
      <Route path="/search" element={<Search />} />
      <Route path="/login/authorize" element={<SsoAuthorize />} />
      <Route path="/login" element={<AuthRoute redirectOnAuth={true}><Login /></AuthRoute>} />
      <Route path="/logout" element={<LogoutComponent />} />
      <Route path="/signup" element={<Navigate to="/register/account" />} />
      <Route path="/register" element={<Navigate to="/register/account" />} />
      <Route path="/signup/*" element={<Navigate to="/register/account" />} />
      <Route path="/register/*" element={<AuthRoute redirectOnAuth={true}><Signup /></AuthRoute>} />
      <Route path="/forgot-password" element={<AuthRoute redirectOnAuth={true}><ForgotPassword /></AuthRoute>} />
      <Route path="/media/videos/:url/" element={<Article />} />
      <Route path="/videos/*" element={<VideoProfile />} />
      <Route path="/videos" element={<Videos />} />
      <Route path="/musicweremember" element={<Concert />} />
      <Route path="/join" element={<Contributor />} />
      <Route path="/create-care-recipient" element={<CreateCareRecipient />} />
      <Route path="/contributors/:key" element={<ContributorDetails />} />
      <Route path="/partner/*" element={<Brand prefix="partner" />} />
      <Route path="/support/:groupKey" element={<Support />} />
      <Route path="/support" element={<Support />} />
      <Route path="/manage/*" element={<AuthRoute><Manage /></AuthRoute>} />
      <Route path="/chat" element={<ForumRedirect />} />
      <Route path="/partners/:partnerKey" element={<PartnerComponent />} />
      <Route path="/partners" element={<MarketingPage />} />
      <Route path="/collections/:collection" element={<CollectionComponent />} />
      <Route path="/collections" element={<Collections />} />
      <Route path="/training/*" element={<Brand prefix="training" />} />
      <Route path="/*" element={<Article />} />

    </Routes>
  </Layout>
);
export default routes;
