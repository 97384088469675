export const AccountTranslations = {
    accountTranslations:{
        account: 'Account',
        careRecipient: 'My Care Recipient',
        myRewards: 'My Rewards',
        saved: 'Saved',
        following: 'Following',
        incorrectPassword: 'Password is incorrect',
        updatedPassword: 'Password updated',
        updatedName: 'Name updated',
        updatedEmail: 'Email updated',
        updatedUsername: 'Username updated',
        updatedZip: 'Zip Code updated',
        updatedGender: 'Gender updated',
        updatedRace: 'Race updated',
        updatedBirthday: 'Birthday updated',
        updatedEmploymentStatus: 'Employment Status updated',
        updatedProfilePhoto: 'Profile photo updated',
        removedProfilePhoto: 'Profile photo removed'
    }
}
