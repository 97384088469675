// Just add the import (MUST BE IN ALPHABETICAL ORDER) and the webpack plugin will handle installing it

import BiCaretUpFill from '~icons/bi/caret-up-fill'
import MdiLightLoading from '~icons/mdi-light/loading'
import BiChatDotsFill from '~icons/bi/chat-dots-fill'
import BxDotsVerticalRounded from '~icons/bx/dots-vertical-rounded'
import BiFacebook from '~icons/bi/facebook'
import BiInfo from '~icons/bi/info'
import BiLinkedin from '~icons/bi/linkedin'
import BiPlus from '~icons/bi/plus'
import BiTrophyFill from '~icons/bi/trophy-fill'
import BiTwitterX from '~icons/bi/twitter-x'
import BiX from '~icons/bi/x'
import BiYoutube from '~icons/bi/youtube'
import BxBxsCheckCircle from '~icons/bx/bxs-check-circle'
import BxHide from '~icons/bx/hide'
import BxShow from '~icons/bx/show'
import Fa6RegularCalendarDays from '~icons/fa6-regular/calendar-days'
import Fa6SolidAsterisk from '~icons/fa6-solid/asterisk'
import Fa6SolidChevronDown from '~icons/fa6-solid/chevron-down'
import Fa6SolidChevronUp from '~icons/fa6-solid/chevron-up'
import Fa6SolidChevronLeft from '~icons/fa6-solid/chevron-left'
import Fa6SolidChevronRight from '~icons/fa6-solid/chevron-right'
import Fa6SolidMicrophone from '~icons/fa6-solid/microphone'
import Fa6SolidVideo from '~icons/fa6-solid/video'
import Fa6SolidPlay from '~icons/fa6-solid/play'
import HeroiconsChatBubbleOvalLeft from '~icons/heroicons/chat-bubble-oval-left'
import HeroiconsOutlineInformationCircle from '~icons/heroicons-outline/information-circle'
import HeroiconsOutlineUserCircle from '~icons/heroicons-outline/user-circle'
import HeroiconsSolidBolt from '~icons/heroicons-solid/bolt'
import HeroiconsSolidPencilAlt from '~icons/heroicons-solid/pencil-alt'
import HeroiconsUsers from '~icons/heroicons/users'
import MaterialSymbolsWarningRounded from '~icons/material-symbols/warning-rounded'
import MdiCircleMedium from '~icons/mdi/circle-medium'
import MdiClose from '~icons/mdi/close'
import MdiPlayCircle from '~icons/mdi/play-circle'
import MdiTextSearchVariant from '~icons/mdi/text-search-variant'
import RiInstagramFill from '~icons/ri/instagram-fill';


// Note: Don't change the keys unless you are prepared to update it throughout the codebase. The keys map to react-icons
// if you want to use the iconify value, we can export it twice; ie {BiHide: BxHide, BxHide: BxHide }

export default {
  AiFillCaretUp: BiCaretUpFill,
  AiOutlineLoading: MdiLightLoading,
  AiOutlinePlus: BiPlus,
  BiChevronDown: Fa6SolidChevronDown,
  BiChevronUp: Fa6SolidChevronUp,
  BiDotsVerticalRounded: BxDotsVerticalRounded,
  BiHide: BxHide,
  BiShow: BxShow,
  BsAsterisk: Fa6SolidAsterisk,
  BsCheckCircleFill: BxBxsCheckCircle,
  BsChevronLeft: Fa6SolidChevronLeft,
  BsDot: MdiCircleMedium,
  BsFacebook: BiFacebook,
  BsFillChatDotsFill: BiChatDotsFill,
  BsInfo: BiInfo,
  BsLinkedin: BiLinkedin,
  BsPlus: BiPlus,
  BsTwitter: BiTwitterX,
  BsX: BiX,
  BsYoutube: BiYoutube,
  FaChevronLeft: Fa6SolidChevronLeft,
  FaMicrophone: Fa6SolidMicrophone,
  FaPlay: Fa6SolidPlay,
  FaPlus: BiPlus,
  FaRegCalendarAlt: Fa6RegularCalendarDays,
  FaTrophy: BiTrophyFill,
  GoPlus: BiTrophyFill,
  HiOutlineChatBubbleOvalLeft: HeroiconsChatBubbleOvalLeft,
  HiOutlineInformationCircle: HeroiconsOutlineInformationCircle,
  HiOutlineUserCircle: HeroiconsOutlineUserCircle,
  HiOutlineUsers: HeroiconsUsers,
  HiSolidBolt: HeroiconsSolidBolt,
  HiPencilAlt: HeroiconsSolidPencilAlt,
  IoIosArrowBack: Fa6SolidChevronLeft,
  IoIosArrowForward: Fa6SolidChevronRight,
  IoIosWarning: MaterialSymbolsWarningRounded,
  IoMdClose: MdiClose,
  IoMdVideocam: Fa6SolidVideo,
  MdClose: MdiClose,
  MdKeyboardArrowDown: Fa6SolidChevronDown,
  MdKeyboardArrowLeft: Fa6SolidChevronLeft,
  MdKeyboardArrowRight: Fa6SolidChevronRight,
  MdManageSearch: MdiTextSearchVariant,
  MdOutlineClose: MdiClose,
  MdPlayCircleFilled: MdiPlayCircle,
  RiInstagramFill: RiInstagramFill,
  VscCircleFilled: MdiCircleMedium,
}
