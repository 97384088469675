import React, {memo, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';
import {PostTypeIds} from '@/constants/post-type-ids.constant';
import {Card} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import styles from './article-card.module.scss';
import Avatar from '@/components/atoms/avatar';
import Image from '@/components/atoms/image';
import {ImageSizes} from '@/constants/image-sizes.constant';
import VerifiedIcon from '@/assets/icons/verified-contributor-badge.svg';
import {ArticleCardSizes} from '@/constants/article-card-sizes.constant';
import CollectionTab from '@/components/atoms/collection-tab';
import CompletionTime from '@/components/atoms/completion-time';

const ArticleCardComponent = ({
    collection,
    img,
    imgAlt,
    title,
    author,
    verified,
    authorImg,
    authorCount,
    bodyText,
    link,
    fullURL = false,
    loading,
    imageDefault,
    className,
    type = '',
    readTime = 0,
    showReadTime = true,
    sectionRef,
    ...props
  }) => {
  const [imageOnError, setImageOnError] = useState(undefined);

  const authorDesc = () => {
    if (authorCount > 2) {
      return `${author} and ${authorCount - 1} others`;
    }
    if (authorCount === 2) {
      return `${author} and 1 other`;
    }
    return author;
  }

  const skeleton = <div className="d-flex flex-column">
    <Skeleton height={160} width={260}/>
    <div className="d-flex">
      <Skeleton width={40} height={40} className="mr-1" circle/>
      <div className="d-flex flex-column">
        <Skeleton width={155}/>
        <Skeleton width={50}/>
        <Skeleton count={3} width={215} className="d-block mb-1"/>
      </div>
    </div>
  </div>

  const routingProps = () => link && (!fullURL ? ({
    as: Link, to: link + `${sectionRef ? `#${sectionRef}` : ''}`,
  }) : ({
    as: 'a', href: link + `${sectionRef ? `#${sectionRef}` : ''}`,
  }));

  const onImageError = () => setImageOnError(imageDefault);

  return loading ? skeleton : (<Card {...routingProps()} className={[styles.cgArticleCard, className]} {...props}>
    {type && <p
      className={`${styles.cgArticleCard__type} ${styles.cgArticleCard__typeArticle}`}>
      {type === PostTypeIds.PODCAST ? 'Podcast' : type === PostTypeIds.VIDEO ? 'Video' : 'Article'}
    </p>}
    <div className={styles.cgArticleCard__image__wrapper}>
      <Image onError={onImageError} className={styles.cgArticleCard__img} src={imageOnError || img}
             alt={imgAlt || title || ''} size={type === PostTypeIds.PODCAST ? ImageSizes.FULL : ImageSizes.MEDIUM}/>
      {collection && collection?.status === 'active' && <CollectionTab collection={collection}/>}
    </div>
    <Card.Body className={`${styles.cgArticleCard__body} ${author && styles.cgArticleCard__body__withAuthor}`}>
      <Card.Subtitle className={styles.cgArticleCard__bodySubtitle}>
        <div className={styles.cgArticleCard__bodyAuthors}>
          {author && <Avatar
            className={`${styles.cgArticleCard__authorAvatar} ${styles.cgArticleCard__avatar}`}
            photo={authorImg}
            name={author}
            count={authorCount}/>}
          <span className={styles.cgArticleCard__authorNames}>{authorDesc()} {author && verified && <VerifiedIcon/>}</span>
        </div>
          {showReadTime && <CompletionTime time={readTime} type={type}/>}
      </Card.Subtitle>
      <Card.Title className={styles.cgArticleCard__bodyTitle}>{title}</Card.Title>
      <Card.Text className={styles.cgArticleCard__bodyText}>{bodyText}</Card.Text>
    </Card.Body>
  </Card>);
};

ArticleCardComponent.propTypes = {
  img: PropTypes.any,
  imgAlt: PropTypes.string,
  imageDefault: PropTypes.string,
  size: PropTypes.oneOf(Object.values(ArticleCardSizes)),
  title: PropTypes.string,
  author: PropTypes.string,
  verified: PropTypes.bool,
  authorImg: PropTypes.string,
  authorCount: PropTypes.number,
  bodyText: PropTypes.string,
  link: PropTypes.string,
  fullURL: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.any,
  type: PropTypes.any,
  collection: PropTypes.object,
  readTime: PropTypes.number,
  showReadTime: PropTypes.bool,
  sectionRef: PropTypes.string,
};

export default memo(ArticleCardComponent);
