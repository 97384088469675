import FacebookIcon from '@/assets/icons/facebook.svg';
import InstagramIcon from '@/assets/icons/instagram.svg';
import LinkedInIcon from '@/assets/icons/linkedin.svg';
import DiscoverIcon from '@/assets/icons/discover.svg';
import SupportIcon from '@/assets/icons/support.svg';
import CollectionsIcon from '@/assets/icons/collections.svg';
import AboutIcon from '@/assets/icons/about.svg';
import PodcastsIcon from '@/assets/icons/podcasts.svg';
import VideosIcon from '@/assets/icons/videos.svg';
import AwardsIcon from '@/assets/icons/awards.svg';
import DiscoverActiveIcon from '@/assets/icons/discover-active.svg';
import SupportActiveIcon from '@/assets/icons/support-active.svg';
import CollectionsActiveIcon from '@/assets/icons/collections-active.svg';
import AboutActiveIcon from '@/assets/icons/about-active.svg';
import PodcastsActiveIcon from '@/assets/icons/podcasts-active.svg';
import VideosActiveIcon from '@/assets/icons/videos-active.svg';
import AwardsActiveIcon from '@/assets/icons/awards-active.svg';
import TwitterIcon from '@/assets/icons/twitter.svg';
import YouTubeIcon from '@/assets/icons/youtube.svg';
import Avatar from '@/components/atoms/avatar';
import FooterLinks from '@/components/molecules/footer-links';
import NavGroup from '@/components/molecules/nav-group';
import {useHttpGet, useObservable} from '@/hooks';
import {UserObservable} from '@/observables/user.observable';
import {LayoutObservable} from '@/observables/layout.observable';
import {SupportLayerObservable} from '@/observables/support-layer.observable';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import styles from './side-menu.module.scss';
import {LanguageHelper} from '@/languages/helper';
import ArrowUp from '@/assets/icons/arrow-up.svg';
import ArrowDown from '@/assets/icons/arrow-down.svg';
import CgIcon from '@/components/atoms/cg-icon';
import { LOGIN_ROUTE } from '@/constants/redirect-login';
import {BRANDS} from '@/constants/endpoints.constant';
import {ImageSizes} from '@/constants/image-sizes.constant';
import {getParsedImageURL} from '@/utils/images';

const WHITELISTED_DEMO_ACCOUNTS = [
    'demo@caregiving.com'
];

const mainLinks = () => {
    return [
        {
            title: LanguageHelper('organismsTranslations.sideMenu.discover'),
            icon: <DiscoverIcon/>,
            activeIcon: <DiscoverActiveIcon/>,
            links: ['/'],
        },
        {
            title: LanguageHelper('organismsTranslations.sideMenu.support'),
            icon: <SupportIcon/>,
            activeIcon: <SupportActiveIcon/>,
            links: ['/support'],
        },
        {
            title: LanguageHelper('organismsTranslations.sideMenu.collections'),
            icon: <CollectionsIcon/>,
            activeIcon: <CollectionsActiveIcon/>,
            links: ['/collections'],
        },
        {
            title: LanguageHelper('organismsTranslations.sideMenu.about'),
            icon: <AboutIcon/>,
            activeIcon: <AboutActiveIcon/>,
            links: ['/about'],
        },
    ];
}

const mediaLinks = () => {
    return [
        {
            title: LanguageHelper('organismsTranslations.sideMenu.podcasts'),
            icon: <PodcastsIcon/>,
            activeIcon: <PodcastsActiveIcon/>,
            links: ['/podcasts'],

        },
        {
            title: LanguageHelper('organismsTranslations.sideMenu.videos'),
            icon: <VideosIcon/>,
            activeIcon: <VideosActiveIcon/>,
            links: ['/videos'],
        },
        {
            title: LanguageHelper('organismsTranslations.sideMenu.awards'),
            icon: <AwardsIcon/>,
            activeIcon: <AwardsActiveIcon/>,
            links: ['/awards'],
        },
    ];
}

const SideMenuComponent = ({closed, blocked, ...props}) => {
    const [user] = useObservable(UserObservable.user$);
    const [supportLayer] = useObservable(SupportLayerObservable.supportLayer$)
    const [isNfcsHeroEnabled] = useObservable(LayoutObservable.nfcsHeroEnabled$)
    const [isNfcsHeroVisible] = useObservable(LayoutObservable.nfcsHeroInView$)
    const [isNfcsBarEnabled] = useObservable(LayoutObservable.nfcsBarEnabled$)
    const [close, setClose] = useState(closed);
    const [block, setBlock] = useState(blocked);
    const [supportLayerItems, setSupportLayerItems] = useState([]);
    const [toggleMediaLinks, setToggleMediaLinks] = useState(false);
    const loginLink = LOGIN_ROUTE;
    const {execute: getBrands} = useHttpGet();

    useEffect(() => {
        setClose(closed);
        setBlock(blocked);
    }, [closed, blocked]);

    useEffect(() => {
        if (supportLayer && user) {
            getBrands(BRANDS).then(response => {
                const newItems = (response?.items || [])
                    .filter(brand => SupportLayerObservable.hasSupportLayer(supportLayer, brand.slug));

                    if (user){
                        newItems.filter(brand => !(brand.slug === 'acme' && !WHITELISTED_DEMO_ACCOUNTS.includes(user.email)));
                    }


                setSupportLayerItems(prevItems => [...prevItems, ...newItems]);
            });
        }
    }, [supportLayer]);

    const handleClick = function (e) {
        if (e.view.innerWidth < 768) {
            setClose(true);
            props.setClose(true);
        }
    }
    return (
        <div className={`${styles.cgSideMenu} ${close && styles.cgSideMenu__closed} ${isNfcsHeroEnabled && isNfcsHeroVisible ? styles.cgSideMenu__withNfcsHeroInView : ''} ${isNfcsBarEnabled ? styles.cgSideMenu__withNfcsBar : ''}`}
             onMouseEnter={() => setClose(false)} onMouseLeave={() => {
            !block && setClose(true)
        }}>
            <div className={`${styles.cgSideMenu__items} ${close ? styles.cgSideMenu__items__collapsed : ''}`}>
                <div>
                {mainLinks().map((item, index) => {
                    return (
                        <span key={index}>
                            <NavGroup
                                className={styles.cgSideMenu__navGroup}
                                title={item.title}
                                icon={item.icon}
                                activeIcon={item.activeIcon}
                                links={item.links}
                                aliasMatch={item.aliasMatch || null}
                                closed={close}
                                onClick={handleClick}
                                external={item.external}
                                betaIcon={item.betaIcon}
                                absolutePath={item.absolutePath}
                            >
                            </NavGroup>
                        </span>
                    );
                })}

                <Button className={styles.cgSideMenu__toggleMediaLinks} variant='link'  onClick={() => {setToggleMediaLinks(!toggleMediaLinks)}}>{toggleMediaLinks ?
                   <div>
                       <ArrowUp className={styles.cgSideMenu__toggleMediaLinks_icons} /> {!close && 'Less'}
                   </div>  :
                    <div>
                        <ArrowDown className={styles.cgSideMenu__toggleMediaLinks_icons} /> {!close && 'More'}
                    </div>}
                </Button>

                {toggleMediaLinks && <>
                    {mediaLinks().map((item, index) => {
                        return (
                            <span key={index}>
                            <NavGroup
                                className={styles.cgSideMenu__navGroup}
                                title={item.title}
                                icon={item.icon}
                                activeIcon={item.activeIcon}
                                links={item.links}
                                aliasMatch={item.aliasMatch || null}
                                closed={close}
                                onClick={handleClick}
                                external={item.external}
                                betaIcon={item.betaIcon}
                            >
                            </NavGroup>
                        </span>
                        );
                    })}
                </>}

                {supportLayerItems.length > 0 &&
                    <span className={styles.cgSideMenu__supportLayerWrapper}>
                     <hr className={styles.cgSideMenu__supportLayerWrapper__hr}/>
                        {!close && <p className={styles.cgSideMenu__partnersTitle}>Support Partners</p>}
                        {supportLayerItems.map(item => {
                            return (<a key={item.slug} href={`/brands/${item.slug}`} className={styles.cgSideMenu__supportLink}
                               title={item.title}>
                                <div className={styles.cgSideMenu__itemIcon} style={{backgroundImage: `url(${getParsedImageURL(item.logo?.location, ImageSizes.SMALL)})`, backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover'}} />
                                {!close && <p className={styles.cgSideMenu__supportLink_text}>{item.title}</p>}
                            </a>
                        )})}
                     </span>
                }

                </div>

                {!close &&
                    <div className={styles.cgSideMenu__footer}>
                    <FooterLinks>
                        <FooterLinks.LabeledLinks>
                            <FooterLinks.LabeledLinks.Link href='/terms-of-use'>
                                {LanguageHelper('organismsTranslations.termsOfUse')}
                                <CgIcon iconName="VscCircleFilled" className={styles.cgSideMenu__bulletPoint}/>
                            </FooterLinks.LabeledLinks.Link>
                            <FooterLinks.LabeledLinks.Link href='/privacy-policy'>
                                {LanguageHelper('organismsTranslations.privacyPolicy')}
                                <CgIcon iconName="VscCircleFilled" className={styles.cgSideMenu__bulletLast}/>
                            </FooterLinks.LabeledLinks.Link>
                            <FooterLinks.LabeledLinks.Link href='/reprint-permission'>
                                {LanguageHelper('organismsTranslations.reprintPermission')}
                            </FooterLinks.LabeledLinks.Link>
                        </FooterLinks.LabeledLinks>
                        <FooterLinks.IconLinks>
                            <FooterLinks.IconLinks.IconLink href='https://www.facebook.com/CaregivingSupport/'
                                                            target="_blank">
                                <FacebookIcon/>
                            </FooterLinks.IconLinks.IconLink>
                            <FooterLinks.IconLinks.IconLink href='https://www.linkedin.com/company/caregiving'
                                                            target="_blank">
                                <LinkedInIcon/>
                            </FooterLinks.IconLinks.IconLink>
                            <FooterLinks.IconLinks.IconLink href='https://twitter.com/caregiving' target="_blank">
                                <TwitterIcon/>
                            </FooterLinks.IconLinks.IconLink>
                            <FooterLinks.IconLinks.IconLink href='https://www.instagram.com/caregivingsupport/'
                                                            target="_blank">
                                <InstagramIcon/>
                            </FooterLinks.IconLinks.IconLink>
                            <FooterLinks.IconLinks.IconLink href='https://www.youtube.com/c/Caregiving' target="_blank">
                                <YouTubeIcon/>
                            </FooterLinks.IconLinks.IconLink>
                        </FooterLinks.IconLinks>
                    </FooterLinks>
                    </div>
                }
            </div>
            <div className={`d-block d-md-none ${styles.cgSideMenu__user}`} onClick={handleClick}>
                {user ?
                    <>
                        <hr className='mt-0'/>
                        <div className={styles.cgSideMenu__profileWrapper}>
                            {user &&
                                <Link to="/manage/user" className='d-flex mt-1'>
                                    <div className="d-flex align-items-center">
                                        <Avatar
                                            className={styles.cgSideMenu__avatar}
                                            photo={user?.avatar?.location}
                                            name={user?.fullName || user?.fullname || user?.email}/>
                                        {user.fullName || user.fullname}
                                    </div>
                                    <CgIcon iconName="IoIosArrowForward" className={styles.cgSideMenu__icon}/>
                                </Link>}
                        </div>
                    </> :
                    <>
                        <hr className='mt-0'/>
                        <Button className={`${styles.cgSideMenu__signIn} ${styles.cgSideMenu__profileWrapper}`} as='a'
                                href={loginLink}>
                            {LanguageHelper('buttons.login')}
                        </Button>
                    </>
                }
            </div>
        </div>
    );
};

SideMenuComponent.propTypes = {
    closed: PropTypes.bool,
    blocked: PropTypes.bool,
    setClose: PropTypes.func
}

export default SideMenuComponent;
