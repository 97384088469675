const Index = {
  moleculeTranslations: {
    partnerButton: 'Socios con nosotros',
    partnerBannerHeader: '¡Trabajemos juntos!',
    partnerBannerDescLong: 'Únase al creciente número de Planes de Salud, organizaciones de Medicaid / Medicare, organizaciones de Salud Minorista, Agencias Gubernamentales Estatales / Locales y Empleadores que se asocian con Caregiving.com para involucrar y empoderar a los cuidadores familiares en sus organizaciones.',
    partnerBannerDescShort: 'Únase al creciente número de organizaciones que se asocian con Caregiving.com para involucrar y empoderar a los cuidadores familiares.',
    dosage: 'Dosis',
    frequency: 'Frecuencia',
    addConditionsSearch: {
      placeholder: 'Condiciones de búsqueda',
      addButton: 'Agregar otra condición',
      singleAddButton: 'Agregar'
    },
    addMedicationsSearch: {
      placeholder: 'Buscar por nombre de medicamento o NDC',
      addButton: 'Agregar otro medicamento',
      singleAddButton: 'Agregar'
    },
    articleJumbotron: {
      featured: 'Destacado',
    },
    seeAll: 'Ver todo',
    sendNow: 'Enviar ahora',
    calculatorBanner: {
      paragraph1: 'Calcula tu',
      paragraph2: 'salario del cuidador',
      paragraph3: 'Facture a sus senadores por su',
      paragraph4: 'cuidado familiar no remunerado.',
    },
    careBinder: 'CareBinder',
    careBinderBanner: {
      description:
        'Una guía descargable para personalizar sus necesidades de cuidado.',
      button: 'Obtenga el libro de trabajo gratis',
    },
    carePromise: {
      title: 'La promesa del cuidado',
      paragraph1:
        'Nuestra promesa y compromiso con los cuidadores familiares es que su experiencia en Caregiving.com será útil, impactante y placentera. La información que comparte con nosotros solo se utilizará para mejorar su experiencia en el sitio y nunca compartiremos ni venderemos su información individual con ninguna empresa externa. Queremos conectarlo con la información o los recursos que necesita en sus términos. Nunca recibirá llamadas telefónicas o correos electrónicos de empresas que obtuvieron su información de nosotros. Eso no es lo que somos y sabemos que eso no es lo que quieres. Su experiencia y su confianza son nuestra principal prioridad.',
      paragraph2:
        'Cada vez que vea esta insignia, queremos que sea un recordatorio de nuestra promesa y compromiso con usted, el cuidador de la familia.',
    },
    addCareRecipient: 'Agregar un receptor de cuidado',
    expoBanner: {
      title: 'Exposición para cuidadores de adultos mayores y familiares',
      button: 'Únete a nosotras en Indianápolis',
    },
    rsvpBanner: {
      title: '¡Reserva!',
      description:
        'Sintonízate y únete a nosotros EN VIVO el 13 de diciembre a las 8 p. m. ET',
      button: 'RSVP hoy!',
    },
    contributorBanner: {
      title: 'Únete a nuestra comunidad de creadores',
      description:
        'Cuéntanos un poco sobre ti y un miembro de nuestro equipo editorial se pondrá en contacto contigo. Tenga en cuenta que nuestro formulario puede tardar unos minutos en completarse.',
      button: 'Aprende más',
    },
    hwghBanner: {
      title: 'Cómo llegamos aquí',
      description: 'Un podcast sobre lo que significa dar y recibir cuidados.',
      button: 'Escucha ahora',
    },
    nominateBanner: {
      title: '¡Envía una nominación!',
      description:
        'Para nominar a una persona u organización que conozca o para nominarse usted mismo, complete nuestra solicitud. La fecha límite para presentar una nominación es el viernes 18 de noviembre.',
      button: 'Empezar',
    },
    progressCard:
      'Aproveche al máximo su experiencia caregiving.com completando estos elementos:',
    completed: 'Terminada',
    nfcs: {
      description: '<strong>¿Se perdió la Cumbre Nacional de Cuidadores Familiares? Te tenemos.</strong><br>Aún puedes registrarte para acceder a este contenido de más de 30 oradores y expertos.',
      register: 'Accede hoy',
      dates: 'Nov 10 & 11',
      stream: 'Transmitir bajo demanda',
      stream_live: 'Stream en vivo!',
      watch_today_friday: 'Míralo hoy a la 1 p.m. EST.',
      watch_today_saturday: 'Míralo hoy a la 10 a.m. EST.',
      alert: '¡Únete a nosotros en nuestra primera Cumbre Nacional para Cuidadores de Familia!',
      sponsored: 'Presentado por:'
    },
    safetyChecklist: {
      title: 'Lista de verificación de seguridad',
      description:
        'Descargue esta herramienta de evaluación y evalúe la seguridad de su ser querido en el hogar.',
      button: '¡Obtenga la lista de verificación gratis!',
    },
    wwdBanner: {
      title: 'Vino, mujeres y demencia',
      description: 'Hasta que haya una cura, hay comunidad.',
      button: 'Lee ahora',
    },
    profilePhoto: {
      addAPhoto: 'Subir foto',
      updatePhoto: 'Actualizar foto',
      removePhoto:'Quitar foto',
    },
    inviteMembers: {
      action: {
        addAnotherMember: 'Agregar otro miembro',
        save: 'Salvar',
        remove: 'Eliminar',
      },
      email: {
        placeholder: 'Correo electrónico',
        error: {
          required: 'Este campo es obligatorio',
          invalid: 'El correo electrónico no es válido',
        }
      },
      name: {
        placeholder: 'Nombre',
        error: {
          required: 'Este campo es obligatorio',
        }
      },
      saveMember: {
        error: {
          nameEmpty: 'El nombre no puede estar vacío.',
          emailEmpty: 'El correo electrónico no puede estar vacío.',
          relationshipEmpty: 'La relación no puede estar vacía.',
          allFieldsEmpty: 'Los campos no pueden estar vacíos'
        }
      },
    },
    relationshipPlaceholder: {
      text1: 'Este es de ',
      text2: '',
    },
    topicChooser: {
      heading: '¿Qué temas te interesan?',
      description: 'Los temas se utilizan para personalizar su experiencia y serán visibles en su perfil.'
    },
    caringFor: '¿A quién cuidas hoy?',
    noProfile: 'No has creado un perfil de receptor de cuidado.',
    myCareRecipients: 'Mis destinatarios de atención',
    followUs: 'SIGUENOS EN',
    collectionBanner: {
      browseCollection: 'Explorar la colección',
      readArticle: 'Leer',
      shelfTitle: 'Colecciones',
      shelfDescription: 'Explora nuestra colecciones.',
      otherCollection: 'Otras colecciones',
      hover: 'Ir a Coleccion'
    },
    comments: {
      responesesTo: 'Respuestas de:',
      noComments: 'No hay comentarios.',
      addComment: 'Añadir un comentario',
      reply: 'responder',
      cancel: 'Cancelar',
      post: 'Publicar',
      deleteHeader: 'Borrar Este Comentario',
      deleteDescription: 'Los comentarios eliminados no se podran recuperar. ¿Estas seguro que quieres borrarlo?',
      delete: 'Borrar',
      update: 'Actualizar',
      flags: {
        item1: 'Fuera de tema',
        item2: 'Inapropiado',
        item3: 'Spam',
        item4: 'Otros',
      },
      flagComment: 'Reportar Este Comentario',
      flag: 'Reportar',
      flagPlaceholder: 'Háganos saber específicamente lo que le preocupa y proporcione enlaces y ejemplos relevantes cuando sea posible',
      tooltip: 'No se puede dar "me gusta" a su propio commentario',
      signIn: {
        header: 'Inicie sesión para aprovechar al máximo la experiencia de caregiving.com',
        description: '¡Al iniciar sesión en su cuenta, podrá marcar, dar me gusta, comentar y ganar recompensas por participar en la comunidad!',
        button: 'Iniciar sesión'
      },
      view: 'Vista',
      replies: 'respuestas',
      moreReplies: 'Ver más respuestas',
      hide: 'Ocultar comentarios'
    },
    signup_modal: {
      heading: '¡No pierdas tu progreso!',
      body: 'Únase a los muchos cuidadores familiares que utilizan Caregiving.com para acceder a recursos como estos, participar en conversaciones y empoderarse mutuamente en sus procesos de atención.',
      button: 'Crear mi cuenta gratuita',
      existing_account: '¿Ya tienes una cuenta?',
      signin: 'Iniciar sesión',
    }
  },
};

const MoleculeTranslations = Object.assign(Index)

export default MoleculeTranslations;
